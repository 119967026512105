.VideosPage {
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VideosPage-videos {
    --formFontSize: 3rem;
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.filterVideoForm {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;

    form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: var(--formFontSize);
        font-family: var(--displayText);

        select {
            font-size: var(--formFontSize);
            font-family: var(--displayText);
        }

        option {
            font-size: 2rem;
            font-family: var(--displayText);
        }

        * {
            padding: 1rem;
        }
    }
}