.VideoCard {
    width:100%;
    flex-shrink: 0;
    /* max-width: 640px; */
}

.VideoCard:hover .VideoCard-contents {
    outline: solid 5px var(--accent1)
}

@media (min-width:768px){
    .VideoCard{

        max-width: 640px;
        width: 50%;
        flex-shrink: 100%;
    }
}

.VideoCard-contents {
    margin: 20px;
    padding: 20px;
    position: relative;
    height:100%;
    min-height: 300px;

    .floatCard {
        width:90%;
    }
}



.player {
    margin: 2rem;
}


@media (max-width: 768px) {
    .VideoCard .floatCard {
        width: 50%;
    }
}

@media (max-width: 480px) {
    .VideoCard .floatCard {
        width: 80%;
    }
}