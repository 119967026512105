:root {
  --accent1: #d1F960;
  --accent2: #FF351A;
  --light: #EFEAE7;
  --white: #FFFFFF;
  --dark: #171717;
  --faded: #93847B;
  --displayText: "Belleza";
  --baseText: "Space Grotesk";
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/* EXPERIMENTAL AREA */


/* DEFAULTS */
html {
  font-size: 7.5px;
}

@media (min-width:480px) {
  html {
    font-size: 10px;
  }
}

body {
  color: var(--dark);
  background-color: var(--light);
  width: 100%;
  /* margin:1rem; */
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--displayText);
  font-weight: 100;
}

h1 {
  font-size: 5rem;
  font-weight: 100;
  line-height: 95%;
}

h2 {
  font-size: 10rem;
  text-align: center;
  margin-block-end: .25rem;
  margin-block-start: .25rem;
}

h3 {
  margin-block-end: .25rem;
  margin-block-start: .25rem;
  padding: 1rem;
  font-size: 4rem;
}

p,
ul {
  font-family: var(--baseText);
  margin: 1.5rem;
  font-size: 1.5rem;
}

i {
  line-height: 0;
  width: fit-content;
}

a,
a:link,
a:visited {
  color: var(--dark);
  text-decoration: none;
}

svg {
  width: 6rem;
  height: auto;

}

/* NAV */

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  outline: .5px;
  outline-style: solid;
  outline-color: var(--dark);
  padding: .5rem;
}

.Nav-logo svg {
  width: 3rem;
  height: auto;
}

.Nav-links {
  display: flex;
  justify-content: right;
}

.Nav-links>a {
  padding: 1.75rem;
  line-height: 0;
  text-decoration: none;
  color: var(--dark);
  font-size: 1.75rem;
  font-family: var(--baseText);
  position: relative;
}

.Nav-links>a:hover::after {
  content: ' ';
  background-color: var(--accent1);
  width: 75%;
  height: .25rem;
  z-index: 20;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 0 1px var(--dark);
}

@media(max-width: 700px){
  .Hero .Socials {
    max-width: 100%;
    width:100%;
  }

  .Hero .Hero-header {
    width:100%;
  }

  .Hero .Headline-text p {
    margin-right: 10%;
    font-size: 4rem;
  }
}

/* HERO */

.Hero {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.Hero-header {
  flex-basis: 1;
  padding: 0rem 1rem 0rem 2rem;
  /* height:100%; */
}

.Socials {
  max-width: 40%;
  border-left: solid var(--dark) .5px;
}

.Socials-card {
  min-height: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  border: dashed var(--dark) .5px;
}

.Socials-card:hover {
  background-color: var(--dark);
  color: var(--white)
}

.Socials .texture-waves-accent1:hover,
.Socials .texture-waves-accent1:hover {
  background-image: url("../public/images/h-waves-dark.png");
}

/* Headline */
.Headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Headline-text {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.Headline-text p {
  text-transform: uppercase;
  color: var(--faded);
  text-align: right;
  font-size: 3rem;
}

.Headline-text p:hover {
  cursor: pointer;
  color: var(--accent2);
  text-decoration: none;
}

.Headline-separator {
  height: 2.5rem;
  width: 100%;
}

/* ABOUT */

.About {
  height: fit-content;
  width: 100%;
  /* height: fit-content; */
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContentCard {
  margin: 2.5rem;
  padding: 2rem;
  z-index: 10;
  position: relative;
}

@media (min-width:768px) {
  .ContentCard {
    padding: 12%;
  }
}

/* PROJECTS */

.Projects {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Project {
  margin: 2rem 10%;
}

.Projects-headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30rem;
}

.Project-hero {
  height: 300px;
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Video-hero {
  height: 200px;
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Project-hero a:hover {
  color: var(--accent2);
}

.Project-image {
  background-color: var(--accent1);
  height: 100%;
}

.Project-image>img {
  filter: grayscale();
  object-fit: cover;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  opacity: 50%;
  display: block;
}

.Project-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project-footer {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.Project-tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.Project-tools>div {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6rem;
  border: dashed var(--dark) .5px;
}

.Project-summary {
  max-width: fit-content;
  flex-basis: 66%;
  padding: 3rem;
}

.Project-links {
  font-size: 4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.Project-links>a {
  padding: 2rem;
  padding-bottom: .5rem;
}

.Project-links-disclaimer {
  padding: 0px;
  margin: auto;
  width: 100%;
}

@media (min-width:600px) {

  .Project-footer {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
  }


  .Project-tools {
    width: auto;
    flex-wrap: wrap;
    align-self: stretch;
    flex-direction: row-reverse;
  }

  .Project-summary {
    flex-basis: 75%;
  }

  .Project-tools>div {
    flex-basis: 50%;
    width: 50%;
    aspect-ratio: 1;
  }
}

/* FOOTER */
.Footer {
  background-color: var(--dark);
  color: var(--light);
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.Footer-links {
  font-size: 2rem;
  display: flex;
  flex: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem;
}

.Footer-links>a {
  color: var(--light);
  padding: 1rem;
  text-align: left;
}

.Footer-copyright {
  font-size: 1.5rem;
  font-family: var(--displayText);
  font-weight: 100;
  text-align: center;
}

/* LAYOUT UTILITIES */

.columns-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

.columns-2>* {
  width: 50%;
}

.square {
  aspect-ratio: 1;
}

.BgGrid {
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  z-index: -10;
}

.floatCard {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  z-index: 10;
  text-align: center;
  display: flex;
}

.floatCard>div {
  width: 100%;
}

.floatCard::after {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transform: translate(.5rem, .5rem);
  width: 100%;
  height: 100%;
  background: var(--white);
  z-index: -1;
  box-shadow: 0 0 0 1px var(--dark);
  text-align: center;
}

.floatCard .Headline-separator {
  outline: none;
  height: .5em;
}

@media(max-width:1000px){
  .floatCard {
    width:75%;
  }
  .floatCard h3 {
    /* font-size: 2rem; */
  }
}

/* STYLE UTILITIES */

.background-accent1 {
  background-color: var(--accent1);
}

.background-accent2 {
  background-color: var(--accent2);
}

.background-light {
  background-color: var(--light);
}

.background-white {
  background-color: var(--white);
}

.border-solid {
  box-shadow: 0 0 0 1px var(--dark);
}

.border-dashed {
  outline: dashed var(--dark) .5px;
}

.texture-noise1 {
  background-image: url("../public/images/lightGrainLg.png");
  background-size: 500px 100%;
  background-repeat: no-repeat;
  background-blend-mode: screen;
  animation: jitterNoise 1s infinite;
}

.texture-noise2 {
  /* background-image: url("../public/images/darkGrainLg.png"); */
  background-size: 500px 100%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}

.texture-waves {
  background-image: url("../public/images/h-waves-dark.png");
  background-size: 200px 200px;
  background-repeat: repeat;
  background-blend-mode: lighten;
}

.textAlign-center {
  text-align: center;
}

.textAlign-left {
  text-align: left;
}

.smallText {
  font-size: 1rem;
}

.highlight {
  display: inline-block;
  transform: skew(-15deg);
}

.hightlight::after {
  content: '<span>&nbsp;</span>';
}

.texture-waves-accent1 {
  background-image: url("../public/images/h-waves-green.png");
  background-size: 150px 100px;
  background-repeat: repeat;
}

.texture-waves-light {
  background-image: url("../public/images/h-waves-light.png");
  background-size: 150px 100px;
  background-repeat: repeat;
}

.fullwidth {
  width: 100%;
  flex-basis: 100%;
}